<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				用户详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回用户列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="user != null && user.Id != 'add'">
					<span>用户ID</span>
					<em v-if="user != null">
						{{user.Id}}
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>OA系统用户编码</span>
					<em  v-if="user != null">
						{{user.OaNo}}
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>账号</span>
					<em  v-if="user != null">
						{{user.Account}}
						<el-button size="mini" style="margin-left: 10px;" @click="ResetPas()">重设密码</el-button>
					</em>
				</li>
				
				<li v-if="user != null && user.Id == 'add'">
					<span>账号</span>
					<em  v-if="user != null">
						<el-input v-model="user.Account" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li v-if="user != null && user.Id == 'add'">
					<span>密码</span>
					<em  v-if="user != null">
						<el-input v-model="user.Password" :show-password="true" type="password" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>手机号</span>
					<em>
						{{user.Mobile}}
					</em>
				</li>
				
				<li v-if="user != null && user.Id == 'add'">
					<span>手机号</span>
					<em>
						<el-input v-model="user.Mobile" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>身份级别</span>
					<em  v-if="user != null">
						<el-select placeholder="设置用户类别" v-model="user.Identity">
							<el-option label="买家" value="buyer"></el-option>
							<el-option label="卖家" value="seller"></el-option>
							<el-option label="系统管理员" value="admin"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>账号状态</span>
					<em  v-if="user != null">
						<el-select placeholder="设置账号状态" v-model="user.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{user.CreatedAt}}
					</em>
				</li>
				
				<li v-if="user != null && user.Id != 'add'">
					<span>最后修改时间</span>
					<em  v-if="user != null">
						{{user.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="user != null && user.Id != 'add'" @click="UpdateUser()" type="warning">提交修改</el-button>
						<el-button v-if="user != null && user.Id == 'add'" @click="NewUserPost()" type="warning">添加用户</el-button>
					</em>
				</li>
			</div>
		</div>
		
		<el-dialog
		  title="修改登录密码"
		  :visible="this.isDialogOpen == true"
		  width="500px"
		  :show-close="false">
		  <div class="ReSetPas">
			  <li><span>新密码</span><el-input type="password" :show-password="true" v-model="NewPas"></el-input></li>
			  <li><span>确认新密码</span><el-input type="password" :show-password="true" v-model="NewPas2"></el-input></li>
		  </div>
		  
		  <span slot="footer" class="dialog-footer">
			  <el-button type="primary" @click="UpdateUserPas()">确认修改密码</el-button>
		    <el-button type="danger" @click="isDialogOpen = ''">关 闭</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import {Select,Option,Dialog} from 'element-ui'
	import md5 from 'md5';
	export default {
	  name: 'UserInfo',
	  props: {
	  },
	  data() {
	      return {
			  user:null,
			  isDialogOpen:false,
			  NewPas:'',
			  NewPas2:'',
			  RootRoleList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少用户身份ID，页面无法工作')
			return
		}
		this.GetRootList()
		if(this.$route.params.Id == 'add'){
			this.InitUser()
			return
		}
		this.GetUser(this.$route.params.Id)
	  },
	  methods:{
		  GetRootList(){ //获取顶级
		  		let data = {
		  			Service:'Base',
		  			Class: 'RbacRole',
		  			Action: 'List',
		  			Pid:"",
					Page:1,
					PageSize:500,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.RootRoleList = res.Data.RbacRoleList
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  },
		  InitUser(){
			this.user = {
				Id:'add',
				Account:'',
				Mobile:'',
				Password:'',
				Identity:'buyer'
			}  
		  },
			ResetPas(){
				this.isDialogOpen = true
				this.NewPas = ''
				this.NewPas2 = ''
			},
		    GetUser(_userId){
		  		let that = this
		  		let data = {Service:'User',Class: 'User',Action: 'Get',Id:_userId,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.user = res.Data
		  			
		  		})
		    },
			NewUserPost(){
				let that = this
				let data = {Service:'User',Class: 'User',Action: 'Register',Identity:that.user.Identity,Account:that.user.Account,Password:md5(that.user.Password),Mobile:that.user.Mobile}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					that.$Jump('/do/user/user_list')
				})
			},
			UpdateUser(){
				let that = this
				let data = {Service:'User',Class: 'User',Action: 'Update',Id:that.user.Id,Status:that.user.Status,Identity:that.user.Identity}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.$Jump('/do/user/user_list')
					}
				})
			},
			UpdateUserPas(){
				if(this.NewPas.length < 6){
					this.$message('密码长度不能低于6位')
					return
				}
				if(this.NewPas != this.NewPas2){
					this.$message('两次密码不一致')
					return
				}
				let that = this
				let data = {Service:'User',Class: 'User',Action: 'Update',Id:that.user.Id,Password:md5(that.NewPas)}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					if(res.ErrorId != 0){
						that.$message(res.Msg)
						return
					}
					that.$message('密码修改成功')
					that.isDialogOpen = false
					that.GetUser(that.$route.params.Id)
				})
			}
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.User .List{
	margin-top: 20px;	
}
.User .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.User .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.User .List .One li  em{
	flex: 1;
}
.User .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.ReSetPas{
	
}
.ReSetPas li{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-right: 80px;
}
.ReSetPas li span{
	width: 140px;
	text-align: right;
	padding-right: 10px;
}
</style>
